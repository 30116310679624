<template>
  <div class="invite">
    <h5header :name="$t('invite.desc15')" :right="false" />
    <div class="invite-con">
      <div class="invite-title animate__animated animate__fadeInUp" v-if="level == 1">
        {{ $t("invite.desc29") }}
      </div>
      <div class="invite-title1 animate__animated animate__fadeInUp" v-else>
        <span @click="$router.push(`/invite/info/${userId}?id=${userId}&level=1`)">{{ $t("invite.desc29") }}</span>
        <img src="../../public/images/new/righticon1.png" alt="" /><span>{{
          $t("invite.desc43")
        }}</span>
      </div>
      <div class="invite-select flex animate__animated animate__fadeInUp">
        <div class="select-info info1">
          <div class="select-name">{{ $t("sign.desc17") }}</div>
          <div class="select-input">
            <input type="text" :placeholder="$t('sign.desc33')" v-model="nickname" class="select-user" />
          </div>
        </div>
        <div class="select-info info2">
          <div class="select-name">{{ $t("invite.desc32") }}</div>
          <div class="select-input">
            <el-date-picker @change="dateSelect" v-model="value1" type="daterange" :range-separator="$t('record.desc2')"
              :start-placeholder="$t('record.desc1')" :end-placeholder="$t('record.desc3')">
            </el-date-picker>
          </div>
        </div>
        <div class="select-info">
          <div class="select-name">{{ $t("invite.desc33") }}</div>
          <div class="select-input">
            <el-select v-model="hasDirect" :placeholder="$t('planet.desc1')">
              <el-option :value="0" :label="$t('record.desc14')"></el-option>
              <el-option :value="true" :label="$t('invite.desc30')"></el-option>
              <el-option :value="false" :label="$t('invite.desc31')"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="invite-all flex animate__animated animate__fadeInUp animate__delay-.2s">
        <div class="invite-select flex">
          <div class="select-info">
            <div class="select-name">{{ $t("invite.desc34") }}</div>
            <div class="select-input">
              <el-select v-model="hasPlanet" :placeholder="$t('planet.desc1')">
                <el-option :value="0" :label="$t('record.desc14')"></el-option>
                <el-option :value="true" :label="$t('invite.desc30')"></el-option>
                <el-option :value="false" :label="$t('invite.desc31')"></el-option>
              </el-select>
            </div>
          </div>
          <div class="select-info">
            <div class="select-name">{{ $t("invite.desc35") }}</div>
            <div class="select-input">
              <el-select v-model="hasLand" :placeholder="$t('planet.desc1')">
                <el-option :value="0" :label="$t('record.desc14')"></el-option>
                <el-option :value="true" :label="$t('invite.desc30')"></el-option>
                <el-option :value="false" :label="$t('invite.desc31')"></el-option>
              </el-select>
            </div>
          </div>
          <div class="select-info">
            <div class="select-name">{{ $t("invite.desc36") }} </div>
            <div class="select-input">
              <el-select v-model="hasSelfAchievementAsc" :placeholder="$t('planet.desc1')">
                <el-option :value="1" :label="$t('record.desc14')"></el-option>
                <el-option :value="true" :label="$t('invite.desc37')"></el-option>
                <el-option :value="false" :label="$t('invite.desc38')"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="invite-btn flex">
          <div class="flexcenter" @click="handlerReset">
            {{ $t("asset.desc87") }}
          </div>
          <div class="flexcenter" @click="handlerSearch">
            {{ $t("asset.desc88") }}
          </div>
        </div>
      </div>
      <div class="h5-record animate__animated animate__fadeInUp animate__delay-.5s">
        <div class="record-list" v-for="item in tableList" :key="item.id">
          <div class="list-top flex" @click="handlerNext(item)">
            <img v-if="item.avatar" :src="item.avatar" alt="" class="avatar" />
            <img v-else
              src="https://meta2032.s3.ap-east-1.amazonaws.com/upload/20220228/c3bfb0cb4486c120207026a84690a972.png"
              class="avatar" alt="" />
            <div class="top-info">
              <div class="top-name">{{ handleOmit(item.userId) }}</div>
              <div class="top-name">{{ item.account }}</div>
              <div class="top-email">{{ dealEmail(item.email) }}</div>
            </div>
            <img src="../../public/images/new/righticon6.png" alt="" class="icon" />
          </div>
          <div class="list-info flex">
            <div class="info">
              <div v-if="item.lastLoginTime != 0">{{ item.lastLoginTime }}</div>
              <div v-else>--</div>
              <p>{{ $t("invite.desc32") }}</p>
            </div>
            <div class="info">
              <div>{{ item.directPlanetCount }}</div>
              <p>{{ $t("invite.desc93") }}</p>
            </div>
            <div class="info">
              <div>{{ item.directLandCount }}</div>
              <p>{{ $t("invite.desc94") }}</p>
            </div>
            <div class="info">
              <div>{{ item.teamCount }}</div>
              <p>{{ $t("invite.desc95") }}</p>
            </div>
            <div class="info">
              <div>{{ item.planetCount }}</div>
              <p>{{ $t("invite.desc13") }}</p>
            </div>
            <div class="info">
              <div>{{ item.landCount }}</div>
              <p>{{ $t("invite.desc14") }}</p>
            </div>
            <div class="info">
              <div>{{ item.totalInvestAmount }}</div>
              <p class="flex">{{ $t("invite.desc15") }}
                <!-- <el-tooltip
                      class="box-item"
                      raw-content
                      effect="dark"
                      :content="$t('invite.desc92')"
                      placement="bottom"
                    >
                      <img
                        src="../../public/images/new/tips.png"
                        alt=""
                        class="img1"
                      />
                    </el-tooltip> -->
              </p>
            </div>
          </div>
        </div>
        <div class="no-data" v-if="showNull">
          <img src="../../public/images/no-data.png" alt="" />
          <span>{{ $t("record.desc13") }}</span>
        </div>
      </div>
      <div class="table-record animate__animated animate__fadeInUp animate__delay-.5s">
        <div class="table-thead flex">
          <div>ID</div>
          <div>{{ $t("sign.desc17") }}</div>
          <div>{{ $t("sign.desc20") }}</div>
          <div>{{ $t("invite.desc32") }}</div>
          <div>{{ $t("invite.desc93") }}</div>
          <div>{{ $t("invite.desc94") }}</div>
          <div>{{ $t("invite.desc95") }}</div>
          <!-- <div>{{ $t("invite.desc39") }}</div> -->
          <div>{{ $t("invite.desc13") }}</div>
          <div>{{ $t("invite.desc14") }}</div>
          <div class="flex">{{ $t("invite.desc15") }}
            <!-- <el-tooltip
                      class="box-item"
                      raw-content
                      effect="dark"
                      :content="$t('invite.desc92')"
                      placement="bottom"
                    >
                      <img
                        src="../../public/images/new/tips.png"
                        alt=""
                        class="img1"
                      />
                    </el-tooltip> -->
          </div>
          <div>{{ $t("quotes.desc6") }}</div>
        </div>
        <div class="table-tbody">
          <div class="tbody-info flex" v-for="item in tableList" :key="item.id">
            <div>{{ handleOmit(item.userId) }}</div>
            <div>{{ item.account }}</div>
            <div>{{ dealEmail(item.email) }}</div>
            <div v-if="item.lastLoginTime != 0">{{ item.lastLoginTime }}</div>
            <div v-else>--</div>
            <div>{{ item.directPlanetCount }}</div>
            <div>{{ item.directLandCount }}</div>
            <div>{{ item.teamCount }}</div>
            <div>{{ item.planetCount }}</div>
            <div>{{ item.landCount }}</div>
            <div>{{ item.totalInvestAmount }}</div>
            <div>
              <span @click="handlerNext(item)" class="bt" :class="{ on: item.teamCount == 0 }">{{ $t("invite.desc43")
              }}</span>
            </div>
          </div>
        </div>
        <div class="no-data" v-if="showNull">
          <img src="../../public/images/no-data.png" alt="" />
          <span>{{ $t("record.desc13") }}</span>
        </div>
      </div>
      <div class="planet-page animate__animated animate__fadeInUp animate__delay-1s">
        <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page" :total="totals"
          :hide-on-single-page="true" @current-change="handlerCurrent">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
  components: {
    h5header,
  },
  data() {
    return {
      value1: "",
      hasDirect: 0,
      hasPlanet: 0,
      hasLand: 0,
      hasSelfAchievementAsc: 1,
      startLoginTime: "",
      endLongTime: "",
      nickname: "",
      page: 1,
      totals: 0,
      tableList: [],
      showNull: false,
      userId: "",
      level: 1,
    };
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        if (to.query.id != undefined) {
          this.userId = to.query.id;
          this.hasDirect = 0;
          this.hasPlanet = 0;
          this.hasLand = 0;
          this.hasSelfAchievementAsc = 1;
          this.startLoginTime = "";
          this.endLongTim = "";
          this.nickname = "";
          this.page = 1;
          this.tableList = [];
          this.getList();
        }
        this.level = 1;
        if (to.query.level == undefined) {
          this.level = 2;
        }
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  mounted() { },
  methods: {
    handlerNext(info) {
      if (info.directCount > 0) {
        this.$router.push(`/invite/info/${info.userId}?id=${info.userId}`);
      }
    },
    handlerCurrent(val) {
      this.page = val;
      this.tableList = [];
      this.getList();
    },
    handlerReset() {
      this.hasDirect = 0;
      this.hasPlanet = 0;
      this.hasLand = 0;
      this.hasSelfAchievementAsc = 1;
      this.startLoginTime = "";
      this.endLongTim = "";
      this.nickname = "";
      this.page = 1;
      this.tableList = [];
      this.getList();
    },
    handlerSearch() {
      this.page = 1;
      this.getList();
    },
    dateSelect(arr) {
      if (arr != null) {
        this.startLoginTime = this.newDate(arr[0]);
        this.endLongTime = this.newDate(arr[1]);
      } else {
        this.startLoginTime = "";
        this.endLongTime = "";
      }
    },
    dealAddress(str) {
      if (str == "" || str == undefined || str == null) return "--";
      if (str && str.length > 10) {
        return (
          str.substring(0, 4) +
          "***" +
          str.substring(str.length - 4, str.length)
        );
      } else {
        return str;
      }
    },
    dealAddress3(str) {
      if (str == "" || str == undefined || str == null) return "--";
      if (str && str.length > 1) {
        return (
          str.substring(0, 1) +
          "***" +
          str.substring(str.length - 1, str.length)
        );
      } else if (str.length == 1) {
        return str.substring(0, 1) + "***";
      } else {
        return str;
      }
    },
    dealName(str) {
      if (str == "" || str == undefined || str == null) return "--";
      return str.substring(0, 1) + "***";
    },
    dealEmail(str) {
      if (!str) {
        return "--";
      }
      if (str.indexOf("@") !== -1) {
        const index = str.indexOf("@");
        let strPrev = str.slice(0, index);
        return strPrev.length > 0
          ? `${this.handleOmit(strPrev)}${str.slice(index, str.length)}`
          : str;
      } else {
        return str;
      }
    },
    newDate(time) {
      // 根据时间查询
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    getList() {
      this.showNull = false;
      this.$post(this.URL.invite.teamlist, {
        account: this.nickname,
        hasDirect: this.hasDirect == 0 ? "" : this.hasDirect,
        hasPlanet: this.hasPlanet == 0 ? "" : this.hasPlanet,
        hasLand: this.hasLand == 0 ? "" : this.hasLand,
        hasSelfAchievementAsc:
          this.hasSelfAchievementAsc === 1 ? "" : this.hasSelfAchievementAsc,
        startLoginTime: this.startLoginTime,
        endLongTime: this.endLongTime,
        page: this.page,
        pageSize: 10,
        parentId: this.userId,
        userId: "",
      }).then((res) => {
        if (res.code == 0) {
          if (res.data.list != '') {
            res.data.list.forEach(e => {

              if (e.lastLoginTime.indexOf(1970) != -1) {
                e.lastLoginTime = 0;
              }
            });
          }
          this.tableList = res.data.list;
          this.totals = parseFloat(res.data.page.count);
          if (this.tableList.length == 0) {
            this.showNull = true;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleOmit(val) {
      if (!val) {
        return "--";
      }
      const str = val.toString();
      const len = str.length;
      if (len >= 5) {
        const middleIndex = (len - 1) / 2;
        return `${str.slice(0, middleIndex - 1)}***${str.slice(
          middleIndex + 2,
          len
        )}`;
      } else if (len >= 2) {
        return `${str.slice(0, 1)}***${str.slice(len - 1, len)}`;
      } else {
        return `${val}***`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invite {
  padding: 136px 0 80px;
  background: url("../../public/images/new/landbg.png") center no-repeat;
  background-size: 100% 100%;

  .invite-con {
    width: 1200px;
    margin: 0 auto;

    .invite-title {
      font-size: 24px;
      color: #ffffff;
    }

    .invite-title1 {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      img {
        width: 12px;
        height: 14px;
        margin: 0 8px;
      }

      span {
        cursor: pointer;

        &:last-child {
          color: #fff;
          cursor: inherit;
        }
      }
    }

    .invite-all {
      justify-content: space-between;
    }

    .invite-select {
      margin-top: 24px;
      width: 768px;

      .select-info {
        flex: 0 0 160px;

        &:nth-child(2) {
          margin: 0 24px;
        }

        .select-name {
          margin-bottom: 10px;
          font-size: 11px;
          color: #a8a8a8;
        }

        .select-user {
          width: 100%;
          height: 40px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          padding: 0 16px;
          color: #fff;

          &:hover {
            border: 1px solid rgba(210, 251, 113, 1);
          }
        }
      }

      .info1 {
        flex: 0 0 240px;
      }

      .info2 {
        flex: 0 0 320px;
      }

      ::v-deep .el-date-editor {
        width: 320px;
        height: 40px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid rgba(255, 255, 255, 0.2) !important;

        .el-range-input {
          color: #fff;
        }

        .el-range-separator {
          color: #fff;
        }
      }

      ::v-deep .el-select {
        width: 160px;

        .el-input__wrapper {
          background-color: rgba(255, 255, 255, 0);
          box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) inset;
          height: 40px;

          .el-input__inner {
            color: #fff;
          }
        }

        .is-focus {
          box-shadow: 0 0 0 1px rgba(210, 251, 113, 1) inset !important;
        }
      }
    }

    .invite-btn {
      flex: 0 0 186px;
      margin-top: 48px;
      justify-content: space-between;

      div {
        flex: 0 0 88px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 16px;
        color: #c9fa5b;
        cursor: pointer;

        &:last-child {
          background: #c9fa5b;
          color: #000;
          border: 1px solid #c9fa5b;
        }
      }
    }
  }

  .table-record {
    margin-top: 24px;

    .table-thead {
      padding: 20px 24px;
      background: #181818;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);

      div {
        flex: 1;
        padding-right: 5px;

        &:first-child {
          flex: 0 0 100px;
        }

        &:nth-child(2) {
          flex: 0 0 80px;
        }

        &:nth-child(3) {
          flex: 0 0 120px;
        }

        &:nth-child(4) {
          flex: 0 0 160px;
        }

        &:last-child {
          flex: 1;
          padding-right: 0;
        }

        img {
          width: 12px;
          height: 12px;
          margin: 4px 0 0 8px;
        }
      }
    }

    .table-tbody {
      .tbody-info {
        padding: 22px 24px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;

        &:hover {
          background: #181818;
        }

        div {
          padding-right: 10px;
          flex: 1;
          font-size: 13px;
          color: #ffffff;

          &:first-child {
            flex: 0 0 100px;
          }

          &:nth-child(2) {
            flex: 0 0 80px;
          }

          &:nth-child(3) {
            flex: 0 0 120px;
          }

          &:nth-child(4) {
            flex: 0 0 160px;
          }

          &:last-child {
            flex: 1;
            padding-right: 0;

            .bt {
              color: #c9fa5b;
              cursor: pointer;

              &.on {
                color: rgba(255, 255, 255, 0.6);
                cursor: inherit;
              }
            }
          }
        }
      }
    }
  }

  .planet-page {
    padding: 0;
    margin-top: 40px;

    ::v-deep .el-pagination {
      button {
        background-color: rgba(255, 255, 255, 0);
        color: #666666;
        font-size: 14px;
      }

      .el-pager {
        li {
          background: rgba(255, 255, 255, 0);
          font-size: 14px;
          color: #666666;
        }

        .is-active {
          background: #c9fa5b !important;
          border-radius: 4px !important;
          color: #000000 !important;
        }
      }
    }
  }

  .no-data {
    width: 100%;
    padding: 130px 0;

    img {
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto 16px;
    }

    span {
      display: block;
      margin: 0 auto;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #a1a1a1;
    }
  }
}

.h5-record {
  display: none;
}

@media (max-width: 1200px) {
  .invite {
    padding: 88px 16px;

    .no-data {
      width: 100%;
      padding: 50px 0;

      img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
      }
    }

    .invite-con {
      width: 100%;

      .invite-select {
        width: 100%;

        .info2 {
          flex: 0 0 240px;
        }

        ::v-deep .el-date-editor {
          width: 240px;
        }
      }

      .table-record {
        display: none;
      }
    }
  }

  .h5-record {
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;

    .record-list {
      flex: 0 0 49%;
      margin-bottom: 2%;
      padding: 16px;
      border-radius: 8px;
      background: #181818;

      &:nth-child(2n) {
        margin-left: 2%;
      }

      .list-top {
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        position: relative;

        .avatar {
          flex: 0 0 62px;
          width: 62px;
          height: 62px;
          margin-right: 12px;
        }

        .top-info {
          flex: 1;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);

          .top-name {
            margin-bottom: 8px;
            font-size: 16px;
            color: #ffffff;
            line-height: 16px;
          }
        }

        .icon {
          width: 5px;
          height: 9px;
          // margin: 20px 0 0 12px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      .list-info {
        flex-wrap: wrap;

        .info {
          margin-top: 16px;
          flex: 0 0 33.3%;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);

          &:first-child {
            flex: 0 0 66.6%;
          }

          div {
            margin-bottom: 8px;
            color: #ffffff;
            font-size: 14px;
          }

          img {
            width: 12px;
            height: 12px;
            margin: 2px 0 0 4px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .invite {
    padding: 52px 16px 80px;

    .invite-con {

      .invite-title,
      .invite-title1 {
        display: none;
      }

      .invite-select {
        margin-top: 12px;
        justify-content: space-between;

        .info2 {
          display: none;
        }

        .select-info {
          flex: 0 0 48%;

          ::v-deep .el-select {
            width: 100%;
          }
        }
      }

      .invite-all {
        justify-content: space-between;
        flex-wrap: wrap;

        .invite-select {
          flex: 0 0 100%;

          .select-info {
            flex: 0 0 28%;

            &:nth-child(2) {
              margin: 0 2%;
            }

            &:last-child {
              flex: 0 0 40%;
            }

            ::v-deep .el-select {
              width: 100%;
            }
          }
        }

        .invite-btn {
          margin-top: 12px;
        }
      }
    }
  }

  .h5-record {
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;

    .record-list {
      flex: 0 0 100%;
      margin-bottom: 10px;

      &:nth-child(2n) {
        margin-left: 0;
      }
    }
  }
}
</style>
